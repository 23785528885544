import Button from "@/components/Common/Buttons/Button/Button";
import InputField from "@/components/Common/Inputs/Input/Input";
import FileInput from "@/components/Common/Inputs/FileInput/FileInput";
import TextArea from "@/components/Common/Inputs/TextArea/TextArea";
import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import CheckBox from "@/components/Common/Inputs/CheckBox/CheckBox";
import YesNoInput from "@/components/Common/Inputs/YesNoInput/YesNoInput";
import MakePremium from "@/components/Components/MakePremium/MakePremium";
import PremiumAdSelection from "@/components/Components/PremiumAdSelection/PremiumAdSelection";
import AdminVendorListItem from "@/components/Components/AdminVendorListItem/AdminVendorListItem";
import CreditWallet from "@/components/Components/CreditWallet/CreditWallet";
import AdUpdate from "@/components/Components/AdUpdate/AdUpdate";
import Pagination from "@/components/Components/Pagination/Pagination";
import UserTypeMobile from "@/components/Header/HeaderMobile/UserTypeMobile/UserTypeMobile";
import UserNavMobile from "@/components/Header/HeaderMobile/UserNavMobile/UserNavMobile";
import PagePreloader from "@/components/Components/PagePreloader/PagePreloader.vue";
import SortingDropdown from "@/components/Components/SortingDropdown/SortingDropdown";


export default {
    name: 'AdminVendorOrders',
    components: {
        SortingDropdown,
        PagePreloader,

        Button,
        CheckBox,
        MakePremium,
        AdUpdate,
        PremiumAdSelection,
        CreditWallet,
        AdminVendorListItem,
        TextArea,
        Dropdown,
        InputField,
        UserTypeMobile,
        UserNavMobile,
        YesNoInput,
        FileInput,
        Pagination
    },
    data() {
        return {
            isLoading: false,
            filterBoxActive: false,
            activeInactiveSwitch: 'active',

            sortingOptions: [
                {
                    title: this.$t('price-ascending'),
                    value: 'price.ASC',
                },
                {
                    title: this.$t('price-descending'),
                    value: 'price.DESC',
                },
                {
                    title: this.$t('create-date'),
                    value: 'createdAt.DESC',
                },
            ],
            selectedOption: {
                title: this.$t('create-date'),
                value: 'create_date',
            },
            orderStatuses: {},
            offset: 0,
            limit: 10,
            orderBy: 'createdAt.DESC',
            selectedFilter: 999, //999 - all statuses
            orders: [],
            totalOrdersCount: null,
            currentPage: 1,
            selectedStatus: null
        }
    },
    watch: {
        selectedFilter: async function (newVal, oldVal) {
            this.selectedFilter = newVal;
            this.offset = 0;
            this.currentPage = 1;
            await this.fetchOrders(newVal);
        }
    },
    async mounted() {
        if (this.$store.state.user.info.type === 'agency') {
            this.$router.push({name: 'NotFoundPage'})
        }
        this.isLoading = true;
        this.orderStatuses[this.$t('all-statuses')] = 999;
        this.orderStatuses[this.$t('order-status-awaiting-payment')] = 0;
        this.orderStatuses[this.$t('order-status-paid')] = 1;
        this.orderStatuses[this.$t('order-status-received')] = 2;
        this.orderStatuses[this.$t('order-status-confirmed')] = 3;
        this.orderStatuses[this.$t('order-status-delivered')] = 4;
        await this.fetchOrders();
        this.isLoading = false;
    },
    methods: {
        async handleSorting(order) {
            this.orderBy = order
            await this.fetchOrders(this.selectedFilter);
        },
        async handlePageChange(page) {
            this.currentPage = page;
            this.offset = (this.currentPage - 1) * this.limit;
            await this.fetchOrders(this.selectedFilter);
        },
        getOrderStatusNameById(id) {
            let status = '';
            Object.keys(this.orderStatuses).forEach((key) => {
                if (this.orderStatuses[key] === parseInt(id)) {
                    status = key;
                }
            });

            return status;
        },
        toggleFilterBox() {
            this.filterBoxActive = !this.filterBoxActive
        },
        selectOption(option) {
            this.toggleFilterBox();
            this.selectedOption = option;
        },
        async fetchOrders(status = null) {
            this.isLoading = true;
            const offset = this.offset;
            const limit = this.limit;
            const orderBy = this.orderBy;
            if (parseInt(status) === 999 || status === 'all-statuses' || status === this.$t('all-statuses')) {
                status = null;
            }

            const result = await this.$store.dispatch("order/getVendorOrders", {status, offset, limit, orderBy});
            this.orders = result.data ?? [];
            this.totalOrdersCount = result.total ?? 0;
            this.isLoading = false;
        }
    },
}
